import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Modal } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import { REQUESTS } from "../../api/requests";

import TEXTS from "../../config/texts";
import country from "../../country.json";

import ResetEmailDrawer from "./ResetEmailDrawer";

import classes from "../styles/profileDrawer.module.scss";
import Message from "../../components/message";
import CustomTextField from "../../components/mui/text-field";
import SelectCustom from "../../components/mui/select";
import { MenuItem } from "@mui/material";

const ProfileDrawer = ({ visible, onClose, getProfile }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const profile = useSelector((state) => state.globalState.profile);
  const translation = useSelector((state) => state.globalState.translation);

  const [loading, setLoading] = useState(false);
  const [resetVisible, setResetVisible] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: true,
  });

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);

    const body = {
      name: values.name,
      surname: values.surname,
      phone: values.phone,
      address: values.address,
      company: values.company,
      email: values.email,
      country: values.country,
    };

    function callback() {
      setLoading(false);
      getProfile();
      onClose();
    }

    function errorCallback(error) {
      setLoading(false);
      setMessage({
        text: error,
        type: false,
      });
    }

    REQUESTS.PROFILE.EDIT(body, callback, errorCallback);
  };

  const onFinishFailed = (error) => {
    setLoading(false);
  };

  const resetShowDrawer = (event) => {
    event.preventDefault();
    setResetVisible(true);
  };

  const removeMessage = () => {
    setMessage({
      text: "",
      type: true,
    });
  };

  const onSave = () => {
    removeMessage();
    form.submit();
  };

  const onInputEnter = (event) => {
    if (event.key === "Enter") {
      onSave();
    }
  };

  useEffect(() => {
    if (visible) {
      const info = profile.reseller;
      console.log(info);
      if (info) {
        form.resetFields();
        form.setFields([
          { name: "name", value: info.name },
          { name: "phone", value: info.phone },
          { name: "email", value: info.email },
          { name: "country", value: info.country },
          { name: "surname", value: info.surname },
          { name: "address", value: info.address },
          { name: "company", value: info.company },
          { name: "registration_number", value: info.registration_number },
        ]);
      }
    } else {
      removeMessage();
    }
  }, [profile, visible]);

  useEffect(() => {
    const info = profile.reseller;

    if (info) {
      form.resetFields();
      form.setFields([
        { name: "name", value: info.name },
        { name: "phone", value: info.phone },
        { name: "email", value: info.email },
        { name: "country", value: info.country },
      ]);
    }
  }, [profile]);

  useEffect(() => {
    const windowSize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", windowSize);

    return () => window.removeEventListener("resize", windowSize);
  }, []);

  return (
    <>
      <Modal
        width={windowWidth > 600 ? 600 : "75vw"}
        className={`${classes["drawer"]} profileDrawer`}
        title={translation["Edit profile"] || TEXTS["Edit profile"]}
        centered
        onClose={onClose}
        onCancel={onClose}
        open={visible}
        footer={[]}
      >
        <Form
          form={form}
          name="profile"
          layout={"vertical"}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          scrollToFirstError
        >
          <div
            className={`${classes["form-input-block"]} form-wrapper-profile`}
          >
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message:
                    translation["This field is required!"] ||
                    TEXTS["This field is required!"],
                  whitespace: true,
                },
              ]}
            >
              <div className={classes["input-flex-area"]}>
                <label htmlFor="#">
                  {translation["Name"] || TEXTS["Name"]}
                </label>

                <div style={{ width: "100%" }}>
                  <CustomTextField
                    onKeyDown={onInputEnter}
                    value={form.getFieldValue("name")}
                    placeholder={
                      translation["Enter your name"] || TEXTS["Enter your name"]
                    }
                  />
                </div>
              </div>
            </Form.Item>

            <Form.Item name="email">
              <div className={classes["input-flex-area"]}>
                <label htmlFor="#">
                  {translation["Email"] || TEXTS["Email"]}{" "}
                  <span
                    className={classes["drawer-open"]}
                    onClick={resetShowDrawer}
                  >
                    {translation["Reset"] || TEXTS["Reset"]}
                  </span>
                </label>

                <div style={{ width: "100%" }}>
                  <CustomTextField
                    onKeyDown={onInputEnter}
                    value={form.getFieldValue("email")}
                    placeholder={
                      translation["Enter your email"] ||
                      TEXTS["Enter your email"]
                    }
                    disabled={true}
                  />
                </div>
              </div>
            </Form.Item>

            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message:
                    translation["This field is required!"] ||
                    TEXTS["This field is required!"],
                },
              ]}
            >
              <div className={classes["input-flex-area"]}>
                <label htmlFor="#">
                  {translation["Enter your phone number"] ||
                    TEXTS["Enter your phone number"]}
                </label>

                <div style={{ width: "100%" }}>
                  <CustomTextField
                    type="number"
                    onKeyDown={onInputEnter}
                    value={form.getFieldValue("phone")}
                    placeholder={
                      translation["Enter your phone number"] ||
                      TEXTS["Enter your phone number"]
                    }
                  />
                </div>
              </div>
            </Form.Item>

            <Form.Item className={classes["item"]} name="country">
              <div className={classes["input-flex-area"]}>
                <label htmlFor="#">
                  {translation["Country"] || TEXTS["Country"]}
                </label>

                <div style={{ width: "100%" }}>
                  <SelectCustom
                    placeholder={
                      translation["Enter your registration number"] ||
                      TEXTS["Enter your registration number"]
                    }
                  >
                    {country.map((item) => {
                      return (
                        <MenuItem key={item.code} value={`${item.name}`}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </SelectCustom>
                </div>
              </div>
            </Form.Item>
          </div>

          <Message message={message} />

          <Form.Item className={classes["drawer-button"]}>
            <div className={classes["drawer-button"]} style={{ marginTop: 20 }}>
              <Button
                loading={loading}
                type="primary"
                size={"large"}
                icon={<CheckOutlined />}
                onClick={onSave}
              >
                {translation["Save"] || TEXTS["Save"]}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      <ResetEmailDrawer
        visible={resetVisible}
        onClose={() => setResetVisible(false)}
        getProfile={getProfile}
      />
    </>
  );
};

export default ProfileDrawer;
