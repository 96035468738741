import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Form, Modal } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { actions } from "../../../store/index";
import { REQUESTS } from "../../../api/requests";
import Message from "../../../components/message";
import TEXTS from "../../../config/texts";
import ValidateMacAddress from "../../../components/ValidateMacAddress";
import classes from "../style/activateDeviceDrawer.module.scss";

// mui imports
import CustomTextField from "../../../components/mui/text-field/index";
import SelectCustom from "../../../components/mui/select";
import { MenuItem } from "@mui/material";

const ActivateDeviceDrawer = ({
  onClose,
  visible,
  getDevices,
  removeSearchSorter,
  currentDevice,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const dispatch = useDispatch();

  const { setProfile } = bindActionCreators(actions, dispatch);

  const translation = useSelector((state) => state.globalState.translation);

  const appInfo = useSelector((state) => state.globalState.appInfo);

  const [loading, setLoading] = useState(false);

  const [macValue, setMacValue] = useState("");

  const [message, setMessage] = useState({
    text: "",
    type: true,
  });

  const [packages, setPackages] = useState(null);

  const [packageName, setPackageName] = useState("");

  const paymentSettings = useSelector(
    (state) => state.globalState.pymentSettings
  );

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);
    console.log(values, "values");
    const body = {
      mac: macValue,
      package_id: values.package_id,
    };

    if (values.comment) {
      body.comment = values.comment;
    }

    function callback() {
      setLoading(false);
      onClose();
      removeSearchSorter();
      getDevices();
      getProfile();
    }

    function errorCallback(error) {
      setLoading(false);
      setMessage({
        text: error,
        type: false,
      });
    }

    REQUESTS.ACTIVATED_DEVICE.ACTIVATE(body, callback, errorCallback);
  };

  const onValuesChange = (changed, all) => {
    removeMessage();
  };

  const removeMessage = () => {
    setMessage({
      text: "",
      type: true,
    });
  };

  const onSubmit = () => {
    removeMessage();
    form.submit();
  };

  const getPackages = () => {
    REQUESTS.PACKAGES((data) => setPackages(data.rows));
  };

  const getProfile = () => {
    function callback(data) {
      setProfile(data);
    }
    REQUESTS.PROFILE.GET(callback);
  };

  useEffect(() => {
    if (visible) {
      getPackages();
    } else {
      form.resetFields();
      setMacValue("");
      removeMessage();
      setLoading(false);
    }
  }, [visible]);

  useEffect(() => {
    if (currentDevice) {
      form.setFields([
        {
          name: "comment",
          value: currentDevice.reseller_activation?.comment || "",
        },
      ]);

      setMacValue(currentDevice.mac);

      if (packages) {
        let name = packages.find(
          (item) => item.id == currentDevice?.package_id
        )?.["id"];
        if (name) {
          form.setFields([{ name: "package_id", value: name || "" }]);
        }
      }
    }
  }, [currentDevice, packages]);

  useEffect(() => {
    const windowSize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", windowSize);

    return () => window.removeEventListener("resize", windowSize);
  }, []);

  return (
    <Modal
      width={windowWidth > 600 ? 600 : "75vw"}
      title={translation["Activate device"] || TEXTS["Activate device"]}
      centered
      onClose={() => {
        onClose();
        setMacValue("");
      }}
      onCancel={() => {
        onClose();
        setMacValue("");
      }}
      onOk={() => {
        onClose();
        setMacValue("");
      }}
      open={visible}
      footer={[]}
    >
      <Form
        form={form}
        layout={"vertical"}
        name="activate device"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <ValidateMacAddress value={macValue} setValue={setMacValue} />

        <Form.Item name="package_id">
          <div className={classes["input-flex-area"]}>
            <label>{translation["Package"] || TEXTS["Package"]}</label>
            <div style={{ width: "100%" }}>
              <SelectCustom
                placeholder={
                  translation["Select package"] || TEXTS["Select package"]
                }
                onChange={(value) => form.setFieldsValue({ package_id: value })}
              >
                {packages &&
                  packages.map((item) => {
                    const { name, activation_needed, id, schedule } = item;
                    return (
                      <MenuItem key={id} value={id}>
                        {`${name} (${activation_needed} ${
                          translation["Credits"] || TEXTS["Credits"]
                        }, ${schedule})`}
                      </MenuItem>
                    );
                  })}
              </SelectCustom>
            </div>
          </div>
        </Form.Item>

        <Form.Item name="comment">
          <div className={classes["input-flex-area"]}>
            <label htmlFor="">
              {translation["Comment"] || TEXTS["Comment"]}
            </label>

            <div style={{ width: "100%" }}>
              <CustomTextField
                placeholder={translation["Enter text"] || TEXTS["Enter text"]}
              />
            </div>
          </div>
        </Form.Item>

        <Message message={message} />

        <Form.Item className={classes["activate-button"]}>
          <Button
            type="primary"
            loading={loading}
            onClick={onSubmit}
            icon={<CheckOutlined />}
          >
            {translation["Save"] || TEXTS["Save"]}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ActivateDeviceDrawer;
