import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Menu } from "antd";
import {
  DashboardOutlined,
  FundProjectionScreenOutlined,
  BarsOutlined,
  LinkOutlined,
  PicRightOutlined,
} from "@ant-design/icons";

import TEXTS from "../../config/texts";

import "../styles/sidebar.scss";
import { useContext } from "react";
import userContext from "../../context/UserContext";

const NavBar = () => {
  const location = useLocation();
  const { user, setUser } = useContext(userContext);

  const navigate = useNavigate();
  const profile = useSelector((state) => state.globalState.profile);

  const translation = useSelector((state) => state.globalState.translation);

  return (
    <div className="sidebar navbar" trigger={null} collapsible>
      <Menu
        className="menu"
        defaultSelectedKeys={["/dashboard"]}
        selectedKeys={[location.pathname]}
      >
        <Menu.Item
          key={"/dashboard"}
          className="ant-item"
          icon={<DashboardOutlined />}
        >
          <NavLink to="/dashboard">
            {translation["Dashboard"] || TEXTS["Dashboard"]}
          </NavLink>
        </Menu.Item>

        {profile.reseller?.is_reseller && (
          <Menu.Item
            key={"/dashboard/activated"}
            className="ant-item"
            icon={<FundProjectionScreenOutlined />}
          >
            <NavLink to="/dashboard/activated">
              {translation["Devices"] || TEXTS["Devices"]}
            </NavLink>
            <ul className="dropdown-area">
              <li
                onClick={(e) => {
                  navigate("/dashboard/activated");
                  setUser({
                    ...user,
                    VisibleActivateDeviceDrawer: true,
                    BuyMoreActivationsDrawer: false,
                    visibleBuyMoreActivationsDrawer: false,
                    VisibleAddExistingDeviceDrawer: false,
                    visibleBuyMoreActivationsDrawer: false,
                    subresellerDrawer: false,
                  });
                }}
              >
                Activate Device
              </li>
              <li
                onClick={(e) => {
                  navigate("/dashboard/activated");
                  setUser({
                    ...user,

                    VisibleActivateDeviceDrawer: false,
                    BuyMoreActivationsDrawer: false,
                    visibleBuyMoreActivationsDrawer: false,
                    visibleBuyMoreActivationsDrawer: false,
                    subresellerDrawer: false,
                    VisibleAddExistingDeviceDrawer: true,
                  });
                }}
              >
                Add existing device
              </li>
            </ul>
          </Menu.Item>
        )}

        {profile.reseller?.is_reseller && (
          <Menu.Item
            key={"/dashboard/activations"}
            className="ant-item"
            icon={<BarsOutlined />}
          >
            <NavLink to="/dashboard/activations">
              {translation["Credits history"] || TEXTS["Credits history"]}
            </NavLink>
          </Menu.Item>
        )}

        {profile.reseller?.is_referral && (
          <Menu.Item
            key={"/dashboard/referral"}
            className="ant-item"
            icon={<LinkOutlined className="referral-icon" />}
          >
            <NavLink to="/dashboard/referral">
              {translation["Referral links"] || TEXTS["Referral links"]}
            </NavLink>
          </Menu.Item>
        )}

        {profile.reseller?.is_referral && (
          <Menu.Item
            key={"/dashboard/withdrawals"}
            className="ant-item"
            icon={<PicRightOutlined />}
          >
            <NavLink to="/dashboard/withdrawals">
              {translation["Withdrawals history"] ||
                TEXTS["Withdrawals history"]}
            </NavLink>
          </Menu.Item>
        )}
        {profile.reseller?.is_reseller && profile.reseller?.is_referral && (
          <Menu.Item
            key={"/dashboard/resellers"}
            className="ant-item"
            icon={<FundProjectionScreenOutlined />}
          >
            <NavLink to="/dashboard/resellers">
              {translation["My resellers"] || TEXTS["My resellers"]}
            </NavLink>
            <ul className="dropdown-area">
              <li
                onClick={(e) => {
                  navigate("/dashboard/resellers");
                  setUser({
                    ...user,
                    subresellerDrawer: true,
                    BuyMoreActivationsDrawer: false,
                    visibleBuyMoreActivationsDrawer: false,
                    VisibleActivateDeviceDrawer: false,
                    VisibleAddExistingDeviceDrawer: false,
                    visibleBuyMoreActivationsDrawer: false,
                  });
                }}
              >
                Add Reseller
              </li>
            </ul>
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
};

export default NavBar;
